/*
*  Main function to set the clock times
*/

import moment from "moment-timezone"

(function () {
    // Initialise the locale-enabled clocks
    initInternationalClocks();
    // Initialise any local time clocks
    initLocalClocks();
    // Set the initial minute hand container transition, and then each subsequent step
    setUpMinuteHands();
})();

/*
*  Set up an entry for each locale of clock we want to use
*/
function getTimes() {
    const now = new Date();
    // Set the time manually for each of the clock types we're using
    return [
        {
            jsclass: 'js-victoria',
            jstime: moment().tz("America/Los_Angeles"),
            jsname: 'Victoria, Canada'
        },
        {
            jsclass: 'js-seattle',
            jstime: moment().tz("America/Los_Angeles"),
            jsname: 'Seattle, USA'
        },
        {
            jsclass: 'js-guayaquil',
            jstime: moment().tz("America/Guayaquil"),
            jsname: 'Guayaquil, Ecuador'
        },
        {
            jsclass: 'js-ouro-preto',
            jstime: moment().tz("America/Sao_Paulo"),
            jsname: 'Ouro Preto, Brazil'
        },
        {
            jsclass: 'js-santiago',
            jstime: moment().tz("America/Santiago"),
            jsname: 'Santiago, Chile'
        },
    ];
}

/*
* Set up the clocks that use moment.js
*/
function initInternationalClocks() {
    // Initialise the clock settings and the three times
    let times = getTimes();
    for (let i = 0; i < times.length; ++i) {
        const name = times[i].jsname;
        const digital = times[i].jstime.format('LT');
        const hours = times[i].jstime.format('hh');
        const hours_24 = times[i].jstime.format('H')
        const minutes = times[i].jstime.format('mm');
        const seconds = times[i].jstime.format('ss');
        const degrees = [
            {
                hand: 'hours',
                degree: (hours * 30) + (minutes / 2)
            },
            {
                hand: 'minutes',
                degree: (minutes * 6)
            },
            {
                hand: 'seconds',
                degree: (seconds * 6)
            }
        ];
        // Set title and day/night classes for all clocks
        const clocks = document.querySelectorAll('.active .' + times[i].jsclass);
        for (let d = 0; d < clocks.length; d++) {
            clocks[d].title = 'Time in ' + name + ' ' + digital;
            if (hours_24 > 8 && hours_24 < 20) {
                clocks[d].className = clocks[d].className + ' day';
            } else {
                clocks[d].className = clocks[d].className + ' night';
            }
        }
        for (let j = 0; j < degrees.length; j++) {
            // Setup time and rotation in each clock mechanisms
            const elements = document.querySelectorAll('.active .' + times[i].jsclass + ' .' + degrees[j].hand);
            for (let k = 0; k < elements.length; k++) {
                elements[k].style.webkitTransform = 'rotateZ(' + degrees[j].degree + 'deg)';
                elements[k].style.transform = 'rotateZ(' + degrees[j].degree + 'deg)';
                // If this is a minute hand, note the seconds position (to calculate minute position later)
                if (degrees[j].hand === 'minutes') {
                    elements[k].parentNode.setAttribute('data-second-angle', degrees[j + 1].degree);
                }
            }
        }
    }
    // Add a class to the clock container to show it
    let clockElements = document.querySelectorAll('.clock');
    for (let l = 0; l < clockElements.length; l++) {
        clockElements[l].className = clockElements[l].className + ' show';
    }
}

/*
* Starts any clocks using the user's local time
*/
function initLocalClocks() {
    // Get the local time using JS
    const date = new Date;
    const seconds = date.getSeconds();
    const minutes = date.getMinutes();
    const hours = date.getHours();

    // Create an object with each hand and its angle in degrees
    const hands = [
        {
            hand: 'hours',
            angle: (hours * 30) + (minutes / 2)
        },
        {
            hand: 'minutes',
            angle: (minutes * 6)
        },
        {
            hand: 'seconds',
            angle: (seconds * 6)
        }
    ];
    // Loop through each of these hands to set their angle
    for (let j = 0; j < hands.length; j++) {
        const elements = document.querySelectorAll('.local .' + hands[j].hand);
        for (let k = 0; k < elements.length; k++) {
            elements[k].style.transform = 'rotateZ(' + hands[j].angle + 'deg)';
            // If this is a minute hand, note the seconds position (to calculate minute position later)
            if (hands[j].hand === 'minutes') {
                elements[k].parentNode.setAttribute('data-second-angle', hands[j + 1].angle);
            }
        }
    }
}


/*
 * Set a timeout for the first minute hand movement (less than 1 minute), then rotate it every minute after that
 */
function setUpMinuteHands() {
    // More tricky, this needs to move the minute hand when the second hand hits zero
    const containers = document.querySelectorAll('.minutes-container');
    const secondAngle = containers[containers.length - 1].getAttribute('data-second-angle');
    if (secondAngle > 0) {
        // Set a timeout until the end of the current minute, to move the hand
        const delay = (((360 - secondAngle) / 6) + 0.1) * 1000;
        setTimeout(function () {
            moveMinuteHands(containers);
        }, delay);
    }
}

/*
 * Do the first minute's rotation, then move every 60 seconds after
 */
function moveMinuteHands(containers) {
    for (let i = 0; i < containers.length; i++) {
        containers[i].style.webkitTransform = 'rotateZ(6deg)';
        containers[i].style.transform = 'rotateZ(6deg)';
    }
    // Then continue with a 60 second interval
    setInterval(function () {
        for (let i = 0; i < containers.length; i++) {
            if (containers[i].angle === undefined) {
                containers[i].angle = 12;
            } else {
                containers[i].angle += 6;
            }
            containers[i].style.webkitTransform = 'rotateZ(' + containers[i].angle + 'deg)';
            containers[i].style.transform = 'rotateZ(' + containers[i].angle + 'deg)';
        }
    }, 60000);
}


